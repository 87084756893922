<template>
  <v-content class="fundal">
    <router-view />

    <dashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>

<style lang="css">
  .fundal {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://i.imgur.com/rymIBKh.jpg');
    object-fit: contain;
    background-position: center center;
  }

  @media (max-width: 768px) {
    .fundal {
      background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://i.imgur.com/rymIBKh.jpg');
      background-position: center top;
    }
  }
</style>
